import {
  ActionType,
  MeetingStatus,
  Severity,
  useMeetingStatus,
  useNotificationDispatch,
} from 'amazon-chime-sdk-component-library-react';
import React, { useEffect, useState } from 'react';
import routes from '../../constants/routes';
import { useHistory } from 'react-router-dom';
import { t } from 'i18next';

type DemoMeetingStatus =
  | 'connecting'
  | 'connected'
  | 'reconnecting'
  | 'disconnected'
  | 'failed';

const MeetingStatusNotifier: React.FC = () => {
  const meetingStatus = useMeetingStatus();
  const dispatch = useNotificationDispatch();
  const [status, setStatus] = useState<DemoMeetingStatus>();
  const history = useHistory();

  const getMeetingStatusPayload = (message: string, severity: Severity) => {
    return {
      severity,
      message,
      autoClose: true,
      replaceAll: true,
    };
  };

  useEffect(() => {
    switch (meetingStatus) {
      case MeetingStatus.Loading:
        setStatus('connecting');
        dispatch({
          type: ActionType.ADD,
          payload: getMeetingStatusPayload(
            t('notifications.meetingStatusNotifier.connecting'),
            Severity.INFO
          ),
        });
        break;
      case MeetingStatus.Succeeded:
        setStatus('connected');
        if (status === 'reconnecting') {
          dispatch({
            type: ActionType.ADD,
            payload: getMeetingStatusPayload(
              t('notifications.meetingStatusNotifier.reconnected'),
              Severity.SUCCESS
            ),
          });
        } else {
          dispatch({
            type: ActionType.ADD,
            payload: getMeetingStatusPayload(
              t('notifications.meetingStatusNotifier.connected'),
              Severity.SUCCESS
            ),
          });
        }
        break;
      case MeetingStatus.Reconnecting:
        setStatus('reconnecting');
        dispatch({
          type: ActionType.ADD,
          payload: getMeetingStatusPayload(
            t('notifications.meetingStatusNotifier.reconnecting'),
            Severity.WARNING
          ),
        });
        break;
      case MeetingStatus.Failed:
        setStatus('failed');
        dispatch({
          type: ActionType.ADD,
          payload: getMeetingStatusPayload(
            t('notifications.meetingStatusNotifier.failed'),
            Severity.ERROR
          ),
        });
        history.push(routes.HOME);
        break;
      case MeetingStatus.TerminalFailure:
        setStatus('failed');
        dispatch({
          type: ActionType.ADD,
          payload: getMeetingStatusPayload(
            t('notifications.meetingStatusNotifier.terminalFailure'),
            Severity.ERROR
          ),
        });
        history.push(routes.HOME);
        break;
      default:
        break;
    }
    return () => {
      setStatus(undefined);
    };
  }, [meetingStatus]);

  useEffect(() => {
    let id: number;
    if (status === 'reconnecting') {
      id = setInterval(() => {
        dispatch({
          type: ActionType.ADD,
          payload: getMeetingStatusPayload(
            t('notifications.meetingStatusNotifier.reconnecting'),
            Severity.WARNING
          ),
        });
      }, 10 * 1000);
    }
    return () => {
      clearInterval(id);
    };
  }, [status]);

  return null;
};

export default MeetingStatusNotifier;
