import React from 'react';
import useTranscription from '../../../hooks/useTranscription';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useAppState } from '../../../providers/AppStateProvider';

interface TranscriptionLine {
  userName: string;
  userText: string;
}

const Transcription: React.FC = () => {
  const { enableTranscription } = useAppState();
  const { lines, showTranslation, translationAccordion } = useTranscription();

  return (
    <>
      {enableTranscription && (
        <>
          <h6 className="title-button" onClick={translationAccordion}>
            Video transcription
            {showTranslation ? <FiChevronDown /> : <FiChevronUp />}
          </h6>
          {showTranslation && (
            <div id="transcription-section">
              <div id="transcription-wrapper">
                <div id="transcription-text-wrapper">
                  {/* eslint-disable */
                  window.innerWidth <= 940
                    ? lines
                        .slice(Math.max(lines.length - 1, 0))
                        .map((line: TranscriptionLine, index: number) => {
                          return (
                            <div key={index}>
                              <p>
                                <span>{line.userName}</span>: {line.userText}
                              </p>
                            </div>
                          );
                        })
                    : lines
                        .slice(Math.max(lines.length - 10, 0))
                        .map((line: TranscriptionLine, index: number) => {
                          return (
                            <div key={index}>
                              <p>
                                <span>{line.userName}</span>: {line.userText}
                              </p>
                            </div>
                          );
                        })}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Transcription;
