// Get the same button of the n players to iterate them
export function getButtons() {
  const previousButtons = document.querySelectorAll<HTMLElement>(
    'button.op-button.op-previous-cam-button'
  );
  const nextButtons = document.querySelectorAll<HTMLElement>(
    'button.op-button.op-next-cam-button'
  );
  const soundButtons = document.querySelectorAll<HTMLElement>(
    'button.op-button.op-volume-button'
  );
  // const fullScreenButtons = document.querySelectorAll<HTMLElement>(
  //   'button.op-button.op-fullscreen-button'
  // );
  const volumeSlider = document.querySelectorAll<HTMLElement>(
    'div.op-volume-slider-container .op-volume-silder'
  );
  return {
    previousButtons,
    nextButtons,
    soundButtons,
    // fullScreenButtons,
    volumeSlider,
  };
}

export function changeFt() {
  const bmv = document.getElementById('bmv');
  const screenShare = document.getElementById('screen-share');

  if (bmv?.style.getPropertyValue('grid-area')) {
    bmv?.style.removeProperty('grid-area');
    screenShare?.style.setProperty('grid-area', 'ft');
  } else {
    bmv?.style.setProperty('grid-area', 'ft');
    screenShare?.style.removeProperty('grid-area');
  }
}
