// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import styled from 'styled-components';

interface StyledProps {
  active: boolean;
}

export const StyledControls = styled.div<StyledProps>`
  opacity: ${props => (props.active ? '1' : '0')};
  transition: opacity 250ms ease;

  @media screen and (max-width: 940px) {
    opacity: 1;
  }

  .controls-menu {
    width: 100%;
    position: static;
    margin: 0;
    background-color: #e6e7e7;
    border: 1px solid #929699da;
    z-index: 50;
    border-radius: 0.5rem;
  }

  @media screen and (max-width: 768px) {
    .controls-menu {
      width: 100vw;
      /* position: static; */
      /* order: 3; */
      /* position: static; */
      /* bottom: 0; */
      border-radius: 0;
    }
  }
`;
// position: absolute;
// top: calc(100% - 6rem);
// position: relative;
// top: 0;
