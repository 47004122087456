// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useEffect } from 'react';
import {
  VideoTileGrid,
  UserActivityProvider,
} from 'amazon-chime-sdk-component-library-react';
import { StyledLayout, StyledContent } from './Styled';
import NavigationControl from '../../containers/Navigation/NavigationControl';
import { useNavigation } from '../../providers/NavigationProvider';
import MeetingDetails from '../../containers/MeetingDetails';
import MeetingControls from '../../containers/MeetingControls';
import useMeetingEndRedirect from '../../hooks/useMeetingEndRedirect';
import DynamicMeetingControls from '../../containers/DynamicMeetingControls';
import { MeetingMode, Layout } from '../../types';
import { VideoTileGridProvider } from '../../providers/VideoTileGridProvider';
import { DataMessagesProvider } from '../../providers/DataMessagesProvider';
import MeetingStatusNotifier from '../../containers/MeetingStatusNotifier';
import GalleryVideoTileGrid from '../../bemyvega/GalleryVideoTileGrid';
import { useAppState } from '../../providers/AppStateProvider';
import '../../bemyvega/styles/index.css';
import Transcription from '../../bemyvega/GalleryVideoTileGrid/components/Transcription';
const MeetingView = (props: { mode: MeetingMode }) => {
  useMeetingEndRedirect();
  const { showNavbar, showRoster, showChat } = useNavigation();
  const { mode } = props;
  const { layout } = useAppState();
  const { totalCams } = useAppState();

  useEffect(() => {
    return function cleanup() {
      window.location.reload();
    };
  }, []);

  return (
    <UserActivityProvider>
      <DataMessagesProvider>
        <VideoTileGridProvider>
          <div className="main-container-player">
            <StyledLayout
              showNav={showNavbar}
              showRoster={showRoster}
              showChat={showChat}
            >
              <StyledContent>
                {totalCams == 0 ? (
                  <>
                    <VideoTileGrid
                      layout={
                        layout === Layout.Gallery ? 'standard' : 'featured'
                      }
                      className="videos"
                      noRemoteVideoView={<MeetingDetails />}
                    />
                    <Transcription />
                  </>
                ) : (
                  <>
                    <GalleryVideoTileGrid />
                  </>
                )}
                {mode === MeetingMode.Spectator ? (
                  <DynamicMeetingControls />
                ) : (
                  <MeetingControls />
                )}
              </StyledContent>
              <MeetingStatusNotifier />
              <NavigationControl />
            </StyledLayout>
          </div>
        </VideoTileGridProvider>
      </DataMessagesProvider>
    </UserActivityProvider>
  );
};

export default MeetingView;
