import React, { useEffect, useState } from 'react';

import {
  RemoteVideos,
  useContentShareState,
  // useLocalVideo,
  useRemoteVideoTileState,
  VideoGrid,
} from 'amazon-chime-sdk-component-library-react';

import Transcription from './components/Transcription';
import SwitchScreenButton from './components/SwitchScreenButton';
import Cameras from './components/Cameras';
import ScreenShare from './components/ScreenShare';
import LocalCamera from './components/LocalCamera';
import Logo from './components/Logo';
import { useAppState } from '../../providers/AppStateProvider';

const GalleryVideoTileGrid: React.FC = () => {
  const { tiles } = useRemoteVideoTileState();
  const { sharingAttendeeId } = useContentShareState();
  // const { isVideoEnabled } = useLocalVideo();
  const { isFocusModeEnabled } = useAppState();
  const [gridSize, setGridSize] = useState(
    tiles.length > 0 ? tiles.length + 2 : 1
  );

  useEffect(() => {
    const bmvVideoTileGrid = document.querySelector<HTMLElement>(
      '.bmv-video-tile-grid'
    );
    if (tiles.length > 0 || sharingAttendeeId !== null) {
      // setGridSize(tiles.length + 2);
      if (bmvVideoTileGrid) {
        bmvVideoTileGrid.style.gridTemplateColumns = '80% 20%';
      }
    } else {
      // setGridSize(1);
      if (bmvVideoTileGrid) {
        bmvVideoTileGrid.style.gridTemplateColumns = '1fr';
      }
    }
    console.log(tiles.length);
    console.log(sharingAttendeeId);
  }, [tiles, sharingAttendeeId]);

  useEffect(() => {
    if (isFocusModeEnabled == false) {
      setGridSize(tiles.length > 0 ? tiles.length + 2 : 1);
    } else {
      if (sharingAttendeeId) {
        setGridSize(2);
      } else {
        setGridSize(1);
      }
    }
  }, [isFocusModeEnabled]);

  return (
    <>
      <Logo />
      <VideoGrid
        className="bmv-video-tile-grid"
        size={gridSize}
        layout="featured"
        style={{
          backgroundColor: 'rgb(63, 65, 73)',
          // gridTemplateRows: '1fr',
          // gridTemplateColumns: '1fr',
          // gridTemplateAreas: '"ft"',
        }}
      >
        <Cameras />
        {/* <div className="share-and-remote">
          {sharingAttendeeId ? <ScreenShare /> : null}
          {isFocusModeEnabled ? null : (
            <div className="remote-videos">
              <RemoteVideos key="remote-videos" />
            </div>
          )}
        </div> */}
        {screen.width > 940 ? (
          <div className="share-and-remote">
            {sharingAttendeeId ? <ScreenShare /> : null}
            {isFocusModeEnabled || tiles.length === 0 ? null : (
              // {isFocusModeEnabled ? null : (
              <div className="remote-videos">
                <RemoteVideos key="remote-videos" />
              </div>
            )}
          </div>
        ) : (
          <>
            {sharingAttendeeId ? <ScreenShare /> : null}
            {isFocusModeEnabled || tiles.length === 0 ? null : (
              // {isFocusModeEnabled ? null : (
              <div className="remote-videos">
                <RemoteVideos key="remote-videos" />
              </div>
            )}
          </>
        )}
        <SwitchScreenButton />
      </VideoGrid>
      <Transcription />
      <LocalCamera />
    </>
  );
};

export default GalleryVideoTileGrid;
