import React from 'react';
import useSetPlayers from '../../../hooks/useSetPlayers';
const Cameras: React.FC = () => {
  const players = useSetPlayers();
  return (
    <>
      {/* <div id="bmv" key="cam-player" style={{ gridArea: 'ft' }}> */}
      <div id="bmv" key="cam-player">
        {players}
      </div>
    </>
  );
};

export default Cameras;
