// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useEffect } from 'react';
import {
  Heading,
  useNotificationDispatch,
  Severity,
  ActionType,
} from 'amazon-chime-sdk-component-library-react';
import MeetingJoinDetails from '../../containers/MeetingJoinDetails';
import { StyledLayout } from './Styled';
import DeviceSelection from '../../components/DeviceSelection';
import { t } from 'i18next';

const DeviceSetup: React.FC = () => {
  const dispatch = useNotificationDispatch();

  const payload: {
    severity: Severity;
    message: string;
    autoClose: boolean;
    replaceAll: boolean;
  } = {
    severity: Severity.INFO,
    message: t('notifications.voiceFocus'),
    autoClose: false,
    replaceAll: true,
  };

  useEffect(() => {
    dispatch({
      type: ActionType.ADD,
      payload: payload,
    });
  }, []);

  return (
    <StyledLayout>
      <Heading tag="h1" level={3} css="align-self: flex-start">
        {t('deviceSetup.title')}
      </Heading>
      <DeviceSelection />
      <MeetingJoinDetails />
    </StyledLayout>
  );
};

export default DeviceSetup;
