import { useLocation } from 'react-router-dom';

export const AUTH_TOKEN_COOKIE_KEY = 'authToken';

export interface IAuthToken {
  id: string;
  sub: string;
  email: string;
  center_id: string;
  mode: string;
  logo: string;
  exp: number;
}

// Given a cookie key `name`, returns the value of
// the cookie or `null`, if the key is not found.
export function getCookie(cookieKey: string): string | null {
  const cookieKeyLenPlus = cookieKey.length + 1;
  return (
    document.cookie
      .split(';')
      .map(c => c.trim())
      .filter(cookie => {
        return cookie.substring(0, cookieKeyLenPlus) === `${cookieKey}=`;
      })
      .map(cookie => {
        return decodeURIComponent(cookie.substring(cookieKeyLenPlus));
      })[0] || null
  );
}

export function getURLParam(param: string) {
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  return params.get(param);
}
