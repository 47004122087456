// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { PropsWithChildren, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  useMeetingManager,
  // useNotificationDispatch,
  // Severity,
  // ActionType,
} from 'amazon-chime-sdk-component-library-react';

import routes from '../constants/routes';

const NoMeetingRedirect: React.FC<PropsWithChildren> = ({ children }) => {
  const history = useHistory();
  // const dispatch = useNotificationDispatch();
  const meetingManager = useMeetingManager();

  // const payload: { severity: Severity; message: string; autoClose: boolean } = {
  //   severity: Severity.INFO,
  //   message: 'No meeting found, please enter a valid meeting Id',
  //   autoClose: true,
  // };

  /* Note:
  
  I have to make sure that the meeting session has been created.
  
  If there is no meeting session created, which will be the case
  every time the page refreshes, because the meeting session is
  created when the function handleMeeting() is called in the Home
  view, then it redirects you to HOME.

  This is useful when you want the user to be able to create new
  meetings and be always anonymous but in our case, where we want
  the user to join an existing meeting and to take his username
  from an access token (or to be anonymous in case we can't find
  his access token) then we should:

  1. Check if there is a meeting session created
  2. Check if there is a token in the authToken cookie

  If there is a token then we should redirect the user to DEVICE
  saving the meeting ID and the user's username.

  If there isn't a token then we should redirect the user to HOME
  where he can choose a new anonymous username.

  */

  const { roomId } = useParams<{ roomId: string }>();

  useEffect(() => {
    if (!meetingManager.meetingSession) {
      // dispatch({
      //   type: ActionType.ADD,
      //   payload: payload,
      // });
      history.push(`${routes.HOME}${roomId}`);
    }
  }, []);

  return <>{children}</>;
};

export default NoMeetingRedirect;
