import React from 'react';
import { LocalVideo } from 'amazon-chime-sdk-component-library-react';

const LocalCamera: React.FC = () => {
  return (
    <>
      <div id="local-video" key="local-video">
        <LocalVideo nameplate="Me" />
      </div>
    </>
  );
};

export default LocalCamera;
