import styled from 'styled-components';

export const fluidStyles = `
    height: 100%;
    width: 100%;
  `;

export const staticStyles = `
    display: flex;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    width: 20vw;
    max-height: 30vh;
    height: auto;
  
    video {
      position: static;
    }
`;

export const Button = styled.button`
  font-size: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  width: 1.5rem;
  line-height: 0;
  position: absolute;
  z-index: 10;
`;
