// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import styled from 'styled-components';

export const StyledLayout = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  max-width: 75rem;
  padding: 1rem 11rem 2rem;
  margin: 0 auto;

  @media (max-width: 760px) {
    border-right: unset;
    align-items: unset;
    justify-content: unset;
  }

  h1 {
    margin-bottom: 0.5rem;
    font-size: 2rem;
  }

  h1 + div {
    padding-bottom: 0;
    padding-top: 0;
    margin-bottom: 0;
  }

  h2 {
    margin-bottom: 0rem !important;
  }
  h2 ~ div {
    margin-bottom: 0rem;
  }
  h2 ~ div > div {
    margin-bottom: 0.5rem;
  }
  div.sc-kAkpmW.gXrXbN > label {
    margin: 1rem 0 0;
  }

  div.sc-kAkpmW.gXrXbN > label {
    margin-bottom: 0;
  }
  h2 {
    margin-bottom: 0.5rem;
  }
  div.sc-eldPxv.jYHDjd {
    margin-top: 0.5rem;
  }
`;
