// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

 import outputsJSON from '../outputs.json';

// Retrieve the desired value from the parsed JSON object
const apiPath = outputsJSON.APIURL.value + '/';

/* const apiPath = window.__RUNTIME_CONFIG__.REACT_APP_APIURL + '/'; */
const awsPath = '/prod';

export const rootPath: string = window.location.href.includes(awsPath)
  ? `${awsPath}/`
  : '/';

// export const apiPath = process.env.API_PATH

const routes = {
  API: `${apiPath}`,
  HOME: `${rootPath}`,
  DEVICE: `${rootPath}devices`,
  MEETING: `${rootPath}meeting`,
  JOIN: `${rootPath}join`,
  JOIN_BREAKOUT: `${rootPath}joinbreakout`,
};

export default routes;
