import React from 'react';
import logo from '../../styles/images/bmg-logo-hotizontal.svg';

const Logo: React.FC = () => {
  return (
    <>
      <div className="bmg-top-logo">
        <img src={logo}></img>
      </div>
    </>
  );
};

export default Logo;
