import { useEffect, useState } from 'react';
import { useAudioVideo } from 'amazon-chime-sdk-component-library-react';
import { useVideoTileGridState } from '../providers/VideoTileGridProvider';
import { startTranscription, stopTranscription } from '../utils/api';
import { useAppState } from '../providers/AppStateProvider';
const useTranscription = () => {
  const [transcripts, setTranscripts] = useState([]);
  const [lines, setLine] = useState([] as any);
  const audioVideo = useAudioVideo();
  const { attendeeStates } = useVideoTileGridState();

  //Handles show/hide translation when activated
  const [showTranslation, setShowTranslation] = useState<boolean>(true);
  const translationAccordion = (): void => {
    setShowTranslation(!showTranslation);
  };

  //Handles enable/disable transcription
  const {
    enableTranscription,
    setEnableTranscription,
    meetingId,
  } = useAppState();

  const handleTranscription = (): void => {
    enableTranscription
      ? stopTranscription(meetingId)
      : startTranscription(meetingId);
  };

  useEffect(() => {
    if (audioVideo) {
      audioVideo.transcriptionController?.subscribeToTranscriptEvent(
        transcriptEvent => {
          setTranscripts(transcriptEvent);
        }
      );
    }
  }, [audioVideo]);

  useEffect(() => {
    if (transcripts) {
      if (transcripts.results !== undefined) {
        if (!transcripts.results[0].isPartial) {
          if (
            transcripts.results[0].alternatives[0].items[0].confidence > 0.5
          ) {
            const attendeeId =
              transcripts.results[0].alternatives[0].items[0].attendee
                .attendeeId;
            if (attendeeStates[attendeeId]) {
              const userName = attendeeStates[attendeeId].name;
              setLine(lines => [
                ...lines,
                {
                  userName: `${userName}`,
                  userText: `${transcripts.results[0].alternatives[0].transcript}`,
                },
              ]);
            }
          }
        }
      } else if (transcripts.type !== undefined) {
        console.log('hola', transcripts.type);
        if (transcripts.type === 'started' || transcripts.type === 'resumed') {
          setEnableTranscription(true);
        } else {
          setEnableTranscription(false);
        }
      }
    }
  }, [transcripts]);
  return {
    lines,
    showTranslation,
    handleTranscription,
    translationAccordion,
  };
};

export default useTranscription;
