// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useState, useEffect, ChangeEvent } from 'react';
import {
  Roster,
  RosterHeader,
  RosterGroup,
  useRosterState,
  RosterAttendeeType,
  CheckRound,
  Remove,
  IconButton,
} from 'amazon-chime-sdk-component-library-react';
import { t, use } from 'i18next';

import { useNavigation } from '../providers/NavigationProvider';
import RosterAttendeeWrapper from '../components/RosterAttendeeWrapper';
import { useAppState } from '../providers/AppStateProvider';

const MeetingRoster = () => {
  const { roster } = useRosterState();
  const [filter, setFilter] = useState('');
  const { closeRoster } = useNavigation();
  const { meetingId, role, lobbyAttendees } = useAppState();

  let attendees = Object.values(roster);

  if (filter) {
    attendees = attendees.filter((attendee: RosterAttendeeType) =>
      attendee?.name?.toLowerCase().includes(filter.trim().toLowerCase())
    );
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const attendeeItems = attendees.map((attendee: RosterAttendeeType) => {
    const { chimeAttendeeId } = attendee || {};
    return (
      <RosterAttendeeWrapper
        key={chimeAttendeeId}
        attendeeId={chimeAttendeeId}
      />
    );
  });

  function acceptUser(userId) {
    fetch(
      `https://4q0847t4m8.execute-api.eu-west-1.amazonaws.com/prod/lobbies/${meetingId}`,
      {
        method: 'DELETE',
        body: JSON.stringify({
          user_id: userId,
          action_type: 'UserAccepted',
        }),
      }
    );
  }

  function denyUser(userId) {
    fetch(
      `https://4q0847t4m8.execute-api.eu-west-1.amazonaws.com/prod/lobbies/${meetingId}`,
      {
        method: 'DELETE',
        body: JSON.stringify({
          user_id: userId,
          action_type: 'UserDenied',
        }),
      }
    );
  }

  return (
    <Roster className="roster">
      <RosterHeader
        searchValue={filter}
        onSearch={handleSearch}
        onClose={closeRoster}
        title={t('navbar.roster.header.title')}
        badge={attendees.length}
      />
      <RosterGroup>{attendeeItems}</RosterGroup>
      {role !== 'student' && (
        <>
          <RosterHeader title={'Lobby'} badge={lobbyAttendees.length} />
          <RosterGroup>
            {lobbyAttendees.map(attendee => {
              return (
                <div
                  key={attendee.user_id}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0.625rem 1rem',
                  }}
                >
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      fontSize: '0.875rem',
                      color: 'rgb(63, 65, 73)',
                    }}
                    className="ch-name"
                  >
                    {attendee.user_name}
                  </div>
                  <div>
                    <IconButton
                      label="Accept"
                      icon={<CheckRound />}
                      onClick={() => acceptUser(attendee.user_id)}
                    ></IconButton>
                    <IconButton
                      label="Remove"
                      icon={<Remove />}
                      onClick={() => denyUser(attendee.user_id)}
                    ></IconButton>
                  </div>
                </div>
              );
            })}
          </RosterGroup>
        </>
      )}
    </Roster>
  );
};

export default MeetingRoster;
