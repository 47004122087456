import React from 'react';
import { Button } from '../Styled';
import { Dock } from 'amazon-chime-sdk-component-library-react';
import { changeFt } from '../../../utils/playerUtils';
const SwitchScreenButton: React.FC = () => {
  return (
    <>
      {document.getElementById('screen-share')?.childNodes && (
        <Button onClick={changeFt} className="change-focus" key="Button">
          <Dock width="2rem" height="2rem" />
        </Button>
      )}
    </>
  );
};

export default SwitchScreenButton;
