// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React from 'react';

import {
  Navbar,
  NavbarHeader,
  NavbarItem,
  Attendees,
  // SignalStrength,
  Flex,
  ZoomIn,
  ZoomOut,
  useContentShareState,
  Chat,
} from 'amazon-chime-sdk-component-library-react';
import { t } from 'i18next';

import { useNavigation } from '../../providers/NavigationProvider';
import { useAppState } from '../../providers/AppStateProvider';
// import { LocalMediaStreamMetrics } from '../LocalMediaStreamMetrics';
import { Layout } from '../../types';
// import GalleryLayout from '../../components/icons/GalleryLayout';
// import FeaturedLayout from '../../components/icons/FeaturedLayout';
import { useVideoTileGridControl } from '../../providers/VideoTileGridProvider';
import { FaRegClosedCaptioning, FaEye } from 'react-icons/fa';

import useTranscription from '../../hooks/useTranscription';

const Navigation: React.FC = () => {
  const { toggleRoster, closeNavbar, toggleChat } = useNavigation();
  const {
    // theme,
    // toggleTheme,
    layout,
    // setLayout,
    priorityBasedPolicy,
    toggleFocusMode,
  } = useAppState();
  const { sharingAttendeeId } = useContentShareState();
  const { zoomIn, zoomOut } = useVideoTileGridControl();
  const { handleTranscription } = useTranscription();
  return (
    <Navbar className="nav nav-bmv" flexDirection="column" container>
      <NavbarHeader title={t('navbar.header.title')} onClose={closeNavbar} />
      <Flex css="margin-top: 0rem;">
        <NavbarItem
          icon={<Attendees />}
          onClick={toggleRoster}
          className="bmv-nav-icon"
          label={t('navbar.attendees.label')}
        />
        <NavbarItem
          icon={<Chat />}
          onClick={toggleChat}
          className="bmv-nav-icon"
          label={t('navbar.chat.label')}
        />
        {/* <NavbarItem
          icon={
            layout === Layout.Gallery ? <FeaturedLayout /> : <GalleryLayout />
          }
          onClick={(): void => {
            if (layout === Layout.Gallery) {
              setLayout(Layout.Featured);
            } else {
              setLayout(Layout.Gallery);
            }
          }}
          disabled={!!sharingAttendeeId}
          className="bmv-nav-icon"
          label={t('navbar.layout.label')}
        /> */}
        <NavbarItem
          icon={<FaRegClosedCaptioning />}
          onClick={handleTranscription}
          className="bmv-nav-icon bmv-nav-transcription"
          label={t('navbar.transcription.label')}
        />
        <NavbarItem
          icon={<FaEye />}
          onClick={toggleFocusMode}
          className="bmv-nav-icon bmv-nav-transcription"
          label={t('navbar.focusMode.label')}
        />
        {layout === Layout.Gallery && priorityBasedPolicy && (
          <>
            <NavbarItem
              icon={<ZoomIn />}
              onClick={zoomIn}
              label={t('navbar.zoomIn.label')}
              disabled={!!sharingAttendeeId}
            />
            <NavbarItem
              icon={<ZoomOut />}
              onClick={zoomOut}
              label={t('navbar.zoomOut.label')}
            />
          </>
        )}
      </Flex>
      <Flex marginTop="auto">
        {/* <NavbarItem
          icon={<Eye />}
          onClick={toggleTheme}
          label={theme === 'light' ? 'Dark mode' : 'Light mode'}
          className="bmv-nav-icon"
        />
        /> */}
        {/* <NavbarItem
          icon={<SignalStrength />}
          onClick={(): void => {
            // do nothing
          }}
          className="bmv-nav-icon"
          label={t('navbar.mediaMetrics.label')}
        >
          <LocalMediaStreamMetrics />
        </NavbarItem> */}
      </Flex>
    </Navbar>
  );
};

export default Navigation;
