import i18n from 'i18next';
import I18NextHttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const getCurrentHost =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : 'https://meet.bemyvega.dev';

i18n
  .use(I18NextHttpBackend)
  .use(initReactI18next)
  .init({
    lng: 'es',
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${getCurrentHost}/locales/{{lng}}.json`,
    },
  });

export default i18n;
