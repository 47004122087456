import React, { Component } from 'react';
// import { v4 as uuidv4 } from 'uuid';

interface ISource {
  type: string;
  file: string;
}

interface IPlayerProps {
  id: string;
  sources?: ISource[];
  autoRefresh: boolean;
}

class Player extends Component<IPlayerProps> {
  private player: any;

  // static defaultProps: IPlayerProps = {
  //   id: uuidv4(),
  //   autoRefresh: true,
  // };

  constructor(props: IPlayerProps) {
    super(props);

    this.player = null;
  }

  componentDidMount() {
    const newWindowObject = window as any;
    const ovenplayer = newWindowObject.OvenPlayer;

    // console.log('Received sources:', this.props.sources);

    // const loadOvenPlayer = () => {
    //   if (this.player) {
    //     this.player.remove();
    //   }

    //   this.player = ovenplayer.create(this.props.id, {
    //     autoStart: true,
    //     autoFallback: true,
    //     mute: false,
    //     sources: this.props.sources,
    //     controls: true,
    //   });

    //   // Reload OvenPlayer when error occured.
    //   this.player.on('error', function() {
    //     // Wait 2 sec and relaod.
    //     setTimeout(function() {
    //       loadOvenPlayer();
    //     }, 2000);
    //   });
    // };

    // loadOvenPlayer();

    // } else {
    //   this.player = ovenplayer.create(this.props.id, {
    //     autoStart: true,
    //     autoFallback: true,
    //     mute: false,
    //     sources: this.props.sources,
    //     controls: true,
    //   });
    // }

    // Initialize OvenPlayer
    this.player = ovenplayer.create(this.props.id, {
      autostart: true,
      autoFallback: true,
      mute: false,
      sources: this.props.sources,
    });
  }

  getMute() {
    return this.player.getMute();
  }
  setMute(isMute: boolean) {
    return this.player.setMute(isMute);
  }
  getVolume() {
    return this.player.getVolume();
  }
  setVolume(volume: number) {
    return this.player.setVolume(volume);
  }
  getCurrentSource() {
    return this.player.getCurrentSource();
  }

  render() {
    return (
      <>
        <div id={'wp-' + this.props.id} className="player-wrapper">
          <div id={this.props.id}></div>
        </div>
      </>
    );
  }
}

export default Player;
