// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { ChangeEvent, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Checkbox,
  DeviceLabels,
  Flex,
  FormField,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  PrimaryButton,
  Select,
  useMeetingManager,
} from 'amazon-chime-sdk-component-library-react';
import {
  // DefaultBrowserBehavior,
  MeetingSessionConfiguration,
} from 'amazon-chime-sdk-js';

import { getErrorContext } from '../../providers/ErrorProvider';
import routes from '../../constants/routes';
import Card from '../../components/Card';
import Spinner from '../../components/icons/Spinner';
import DevicePermissionPrompt from '../DevicePermissionPrompt';
// import RegionSelection from './RegionSelection';
import { createGetAttendeeCallback, fetchMeeting } from '../../utils/api';
import { useAppState } from '../../providers/AppStateProvider';
import { MeetingMode, VideoFiltersCpuUtilization } from '../../types';
import { MeetingManagerJoinOptions } from 'amazon-chime-sdk-component-library-react/lib/providers/MeetingProvider/types';
import meetingConfig from '../../meetingConfig';
import { IAuthToken, getCookie } from '../../utils/token';

import jwt_decode from 'jwt-decode';
import LanguageSelection from './LanguageSelection';
import {
  TYPE_OF_CONFERENCE_OPTIONS,
  VIDEO_TRANSFORM_FILTER_OPTIONS,
} from '../../constants';

import { useTranslation } from 'react-i18next';

const MeetingForm: React.FC = () => {
  const { t } = useTranslation();
  const meetingManager = useMeetingManager();
  const {
    region,
    meetingId,
    localUserName,
    totalCams,
    meetingMode,
    enableSimulcast,
    priorityBasedPolicy,
    keepLastFrameWhenPaused,
    isWebAudioEnabled,
    videoTransformCpuUtilization: videoTransformCpuUtilization,
    setJoinInfo,
    isEchoReductionEnabled,
    // toggleEchoReduction,
    // toggleWebAudio,
    enableWebAudio,
    // toggleSimulcast,
    // togglePriorityBasedPolicy,
    // toggleKeepLastFrameWhenPaused,
    setMeetingMode,
    setMeetingId,
    setLocalUserName,
    setTotalCams,
    setRegion,
    setLanguage,
    setCpuUtilization,
    skipDeviceSelection,
    // toggleMeetingJoinDeviceSelection,
  } = useAppState();
  const [meetingErr, setMeetingErr] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { errorMessage, updateErrorMessage } = useContext(getErrorContext());
  const history = useHistory();

  // Enable Voice Focus
  enableWebAudio();

  // const browserBehavior = new DefaultBrowserBehavior();

  const { roomId } = useParams<{ roomId: string }>();

  if (roomId) {
    setMeetingId(roomId);
  }

  const authToken = getCookie('authToken');
  if (authToken) {
    const decodedAuthToken: IAuthToken = jwt_decode(authToken);
    setLocalUserName(decodedAuthToken.sub);
  }

  const handleJoinMeeting = async (e: React.FormEvent) => {
    e.preventDefault();
    const id = meetingId.trim();
    const attendeeName = localUserName.trim();

    if (!id || !attendeeName) {
      if (!attendeeName) {
        setNameErr(true);
      }

      if (!id) {
        setMeetingErr(true);
      }

      return;
    }

    setIsLoading(true);
    meetingManager.getAttendee = createGetAttendeeCallback(id);

    try {
      const { JoinInfo } = await fetchMeeting(
        id,
        attendeeName,
        region,
        isEchoReductionEnabled
      );
      setJoinInfo(JoinInfo);
      const meetingSessionConfiguration = new MeetingSessionConfiguration(
        JoinInfo?.Meeting,
        JoinInfo?.Attendee
      );
      if (
        meetingConfig.postLogger &&
        meetingSessionConfiguration.meetingId &&
        meetingSessionConfiguration.credentials &&
        meetingSessionConfiguration.credentials.attendeeId
      ) {
        const existingMetadata = meetingConfig.postLogger.metadata;
        meetingConfig.postLogger.metadata = {
          ...existingMetadata,
          meetingId: meetingSessionConfiguration.meetingId,
          attendeeId: meetingSessionConfiguration.credentials.attendeeId,
        };
      }

      setRegion(JoinInfo.Meeting.MediaRegion);
      meetingSessionConfiguration.enableSimulcastForUnifiedPlanChromiumBasedBrowsers = enableSimulcast;
      if (priorityBasedPolicy) {
        meetingSessionConfiguration.videoDownlinkBandwidthPolicy = priorityBasedPolicy;
      }
      meetingSessionConfiguration.keepLastFrameWhenPaused = keepLastFrameWhenPaused;
      const options: MeetingManagerJoinOptions = {
        deviceLabels:
          meetingMode === MeetingMode.Spectator
            ? DeviceLabels.None
            : DeviceLabels.AudioAndVideo,
        enableWebAudio: isWebAudioEnabled,
        skipDeviceSelection,
      };

      await meetingManager.join(meetingSessionConfiguration, options);
      if (meetingMode === MeetingMode.Spectator) {
        await meetingManager.start();
        history.push(`${routes.MEETING}/${meetingId}`);
      } else {
        setMeetingMode(MeetingMode.Attendee);
        history.push(`${routes.DEVICE}/${meetingId}`);
      }
    } catch (error) {
      updateErrorMessage((error as Error).message);
    }
  };

  const closeError = (): void => {
    updateErrorMessage('');
    setMeetingId('');
    setLocalUserName('');
    setIsLoading(false);
  };

  return (
    <form>
      <Heading tag="h1" level={4} css="margin-bottom: 1rem">
        {/* Join a meeting */}
        {t('home.title')}
      </Heading>
      {roomId ? (
        <>
          <p style={{ marginBottom: '1rem' }}> Meeting Id: {roomId}</p>
        </>
      ) : (
        <FormField
          field={Input}
          label={t('home.inputMeeting.label')}
          value={meetingId}
          infoText={t('home.inputMeeting.infoText')}
          fieldProps={{
            name: 'meetingId',
            placeholder: t('home.inputMeeting.fieldProps.placeholder'),
          }}
          errorText={t('home.inputMeeting.errorText')}
          error={meetingErr}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            setMeetingId(e.target.value);
            if (meetingErr) {
              setMeetingErr(false);
            }
          }}
        />
      )}
      {authToken ? (
        <>
          <p style={{ marginBottom: '1rem' }}> Name: {localUserName}</p>
        </>
      ) : (
        <FormField
          field={Input}
          label={t('home.inputName.label')}
          value={localUserName}
          fieldProps={{
            name: 'name',
            placeholder: t('home.inputName.fieldProps.placeholder'),
          }}
          errorText={t('home.inputName.errorText')}
          error={nameErr}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            setLocalUserName(e.target.value);
            if (nameErr) {
              setNameErr(false);
            }
          }}
        />
      )}
      {/* IP Cameras */}
      <FormField
        field={Select}
        options={TYPE_OF_CONFERENCE_OPTIONS}
        onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
          setTotalCams(e.target.value);
        }}
        value={totalCams}
        label={t('home.selectIpCameras.label')}
      />

      {/* <RegionSelection setRegion={setRegion} region={region} /> */}
      <LanguageSelection setLanguage={setLanguage} />

      <FormField
        field={Checkbox}
        label={t('home.checkBoxSpectatorMode.label')}
        value=""
        checked={meetingMode === MeetingMode.Spectator}
        onChange={(): void => {
          if (meetingMode === MeetingMode.Spectator) {
            setMeetingMode(MeetingMode.Attendee);
          } else {
            setMeetingMode(MeetingMode.Spectator);
          }
        }}
      />
      {/* <FormField
        field={Checkbox}
        label="Enable Web Audio"
        value=""
        checked={isWebAudioEnabled}
        onChange={toggleWebAudio}
        infoText="Enable Web Audio to use Voice Focus"
      /> */}
      {/* Amazon Chime Echo Reduction is a premium feature, please refer to the Pricing page for details.*/}
      {/* {isWebAudioEnabled && (
        <FormField
          field={Checkbox}
          label="Enable Echo Reduction"
          value=""
          checked={isEchoReductionEnabled}
          onChange={toggleEchoReduction}
          infoText="Enable Echo Reduction (new meetings only)"
        />
      )} */}
      {/* BlurSelection */}
      {/* Background Video Transform Selections */}
      <FormField
        field={Select}
        options={VIDEO_TRANSFORM_FILTER_OPTIONS}
        onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
          setCpuUtilization(e.target.value);
        }}
        value={videoTransformCpuUtilization}
        label={t('home.selectVideoTransformFilter.label')}
      />
      {/* Video uplink and downlink policies */}
      {/* {browserBehavior.isSimulcastSupported() && (
        <FormField
          field={Checkbox}
          label="Enable Simulcast"
          value=""
          checked={enableSimulcast}
          onChange={toggleSimulcast}
        />
      )} */}

      {/* {browserBehavior.supportDownlinkBandwidthEstimation() && (
        <FormField
          field={Checkbox}
          label="Use Priority-Based Downlink Policy"
          value=""
          checked={priorityBasedPolicy !== undefined}
          onChange={togglePriorityBasedPolicy}
        />
      )}
      <FormField
        field={Checkbox}
        label="Keep Last Frame When Paused"
        value=""
        checked={keepLastFrameWhenPaused}
        onChange={toggleKeepLastFrameWhenPaused}
      />
      <FormField
        field={Checkbox}
        label="Skip meeting join device selection"
        value=""
        checked={skipDeviceSelection}
        onChange={toggleMeetingJoinDeviceSelection}
        infoText="Please select the devices manually to successfully join a meeting"
      /> */}
      <Flex
        container
        layout="fill-space-centered"
        style={{ marginTop: '2.5rem' }}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <PrimaryButton
            label={t('home.button.label')}
            onClick={handleJoinMeeting}
          />
        )}
      </Flex>
      {errorMessage && (
        <Modal size="md" onClose={closeError}>
          {/* <ModalHeader title={`Meeting ID: ${meetingId}`} /> */}
          <ModalHeader
            title={t('home.errorMessage', { meetingId: meetingId })}
          />
          <ModalBody>
            <Card
              title={t('home.errorMessage.body.title')}
              description={t('home.errorMessage.body.description')}
              smallText={errorMessage}
            />
          </ModalBody>
        </Modal>
      )}
      <DevicePermissionPrompt />
    </form>
  );
};

export default MeetingForm;
