import React from 'react';
import { ContentShare } from 'amazon-chime-sdk-component-library-react';
const ScreenShare: React.FC = () => {
  return (
    <>
      <div id="screen-share" key="screen-share">
        <ContentShare />
      </div>
    </>
  );
};

export default ScreenShare;
