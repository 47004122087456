import React, { ChangeEvent } from 'react';

import { FormField, Select } from 'amazon-chime-sdk-component-library-react';

import { LANGUAGES } from '../../constants';
import i18n from '../../i18n';
import { t } from 'i18next';

interface Props {
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
}

const LanguageSelection: React.FC<Props> = ({ setLanguage }) => {
  return (
    <FormField
      field={Select}
      options={LANGUAGES}
      onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
        setLanguage(e.target.value);
      }}
      value={i18n.language}
      label={t('home.selectLanguage.label')}
    />
  );
};

export default LanguageSelection;
