// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// import {
//   Flex,
//   SecondaryButton,
// } from 'amazon-chime-sdk-component-library-react';

import MeetingForm from '../MeetingForm';
import SIPMeeting from '../SIPMeeting';
import useToggle from '../../hooks/useToggle';
import SIPMeetingProvider from '../../providers/SIPMeetingProvider';
import { StyledDiv, StyledWrapper } from './Styled';
import { useAppState } from '../../providers/AppStateProvider';

const MeetingFormSelector: React.FC = () => {
  // const { isActive, toggle } = useToggle(false);
  const { isActive } = useToggle(false);
  const [isSessionPresent, setIsSessionPresent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { isMeetingPrivate, setIsMeetingPrivate } = useAppState();
  const { roomId } = useParams<{ roomId: string }>();

  useEffect(() => {

    const checkSession = async () => {
      try {
        const response = await fetch(
          `https://dxlt95mqx8.execute-api.eu-west-1.amazonaws.com/dev/check_session`,
          {
            method: "POST",
            
            body: JSON.stringify({ session: roomId })
          }
        );

        if (!response.ok) throw new Error('Session not found');

        const data = await response.json();
        console.log(data);
        setIsSessionPresent(true);
        setIsMeetingPrivate(data.data.private);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsSessionPresent(false);
        setIsLoading(false);
      }
    };

    checkSession();
  }, [roomId, setIsMeetingPrivate]);

  const formToShow = isActive ? (
    <SIPMeetingProvider>
      <SIPMeeting />
    </SIPMeetingProvider>
  ) : (
    <MeetingForm />
  );
  // const buttonText = isActive ? 'Join without SIP' : 'Join via SIP';

  /* <Flex container layout="fill-space-centered" style={{ padding: '2rem' }}>
        <SecondaryButton label={buttonText} onClick={toggle} />
      </Flex> */

  return isLoading ? (
    <h1>Loading...</h1>
  ) : isSessionPresent ? (
    <StyledWrapper>
      <StyledDiv>{formToShow}</StyledDiv>
    </StyledWrapper>
  ) : (
    <h1>This session doesn&apos;t exist</h1>
  );
};

export default MeetingFormSelector;
