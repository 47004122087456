// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useEffect, useState, useRef } from 'react';
import { VoiceFocusTransformDevice } from 'amazon-chime-sdk-js';
import { useHistory } from 'react-router-dom';
import {
  PrimaryButton,
  Flex,
  Label,
  useMeetingManager,
  Modal,
  ModalBody,
  ModalHeader,
  useAudioInputs,
  useVoiceFocus,
} from 'amazon-chime-sdk-component-library-react';
import { t } from 'i18next';

import routes from '../constants/routes';
import Card from '../components/Card';
import { useAppState } from '../providers/AppStateProvider';

const MeetingJoinDetails = () => {
  const meetingManager = useMeetingManager();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const {
    meetingId,
    localUserName,
    role,
    isMeetingPrivate,
    toggleTheme,
    setLobbyAttendees,
  } = useAppState();

  /* Voice Focus */
  const { selectedDevice } = useAudioInputs();
  const { isVoiceFocusSupported, addVoiceFocus } = useVoiceFocus();

  const [isUserAccepted, setIsUserAccepted] = useState(false);
  const [isUserDenied, setIsUserDenied] = useState(false);
  const wsUserId = useRef('');

  function connectToLobby(userId) {
    fetch(
      `https://4q0847t4m8.execute-api.eu-west-1.amazonaws.com/prod/lobbies/${meetingId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
          user_email: `${userId}@bemyvega.es`,
          user_name: localUserName,
        }),
      }
    );
  }

  function updateLobby() {
    fetch(
      `https://4q0847t4m8.execute-api.eu-west-1.amazonaws.com/prod/lobbies/${meetingId}`
    )
      .then(res => res.json())
      .then(data => {
        if (data.status_code === '200') {
          setLobbyAttendees(data.data.items);
        } else if (data.status_code === '400') {
          setLobbyAttendees([]);
        }
      });
  }

  useEffect(() => {
    const exampleSocket = new WebSocket(
      `wss://u6it1i137i.execute-api.eu-west-1.amazonaws.com/prod/?session_id=${meetingId}`
    );

    // exampleSocket.onopen = event => {};

    exampleSocket.onmessage = event => {
      const parsedEvent = JSON.parse(event.data);
      if (parsedEvent.event_code == 'connected') {
        if (role === 'student') {
          const userId = JSON.parse(event.data).data.user_id;
          wsUserId.current = userId;
          connectToLobby(userId);
        } else {
          updateLobby();
        }
      }

      if (parsedEvent.event_code == 'UserAccepted') {
        if (role === 'student') {
          if (parsedEvent.data.user_id === wsUserId.current) {
            setIsUserAccepted(true);
            exampleSocket.close();
          }
        } else {
          updateLobby();
        }
      }

      if (parsedEvent.event_code == 'UserDenied') {
        if (role === 'student') {
          if (parsedEvent.data.user_id === wsUserId.current) {
            setIsUserDenied(true);
            exampleSocket.close();
          }
        } else {
          updateLobby();
        }
      }

      if (parsedEvent.event_code == 'UserJoinedLobby') {
        if (role === 'teacher') {
          updateLobby();
        }
      }
    };
  }, []);

  async function enableVoiceFocus() {
    try {
      let current = selectedDevice;
      if (isVoiceFocusSupported) {
        if (typeof selectedDevice === 'string') {
          current = await addVoiceFocus(selectedDevice);
          console.log(current);
        } else {
          if (selectedDevice instanceof VoiceFocusTransformDevice) {
            current = selectedDevice.getInnerDevice();
          }
        }
      }
      await meetingManager.startAudioInputDevice(current);
    } catch (error) {
      // Handle device selection failure here
      console.error('Failed to enable Voice Focus');
    }
  }
  /* ******** */

  const handleJoinMeeting = async () => {
    setIsLoading(true);

    toggleTheme();

    try {
      await meetingManager.start();
      setIsLoading(false);

      // Enable Voice Focus by default
      if (selectedDevice) {
        enableVoiceFocus();
      }
      // history.push(`${routes.MEETING}/${meetingId}?cams=4`);
      history.push(`${routes.MEETING}/${meetingId}`);
    } catch (error) {
      setIsLoading(false);
      setError((error as Error).message);
    }
  };

  return (
    <>
      <Flex container alignItems="center" flexDirection="column">
        {role === 'student' &&
        isMeetingPrivate &&
        !isUserAccepted &&
        !isUserDenied ? (
          <h3 style={{ marginTop: '2rem', fontSize: '1.5rem' }}>
            Wait until the host accepts you..
          </h3>
        ) : isUserDenied ? (
          <h3 style={{ marginTop: '2rem', fontSize: '1.5rem', color: 'red' }}>
            Your access has been denied
          </h3>
        ) : (
          <PrimaryButton
            label={
              isLoading
                ? t('deviceSetup.button.loading')
                : t('deviceSetup.button.label')
            }
            onClick={handleJoinMeeting}
          />
        )}
        <Label style={{ margin: '.75rem 0 0 0' }}>
          {t('deviceSetup.details', {
            meetingId: meetingId,
            localUserName: localUserName,
          })}
        </Label>
      </Flex>
      {error && (
        <Modal size="md" onClose={(): void => setError('')}>
          <ModalHeader
            title={t('deviceSetup.modalError.header.title', {
              meetingId: meetingId,
            })}
          />
          <ModalBody>
            <Card
              title={t('deviceSetup.modalError.body.title')}
              description={t('deviceSetup.modalError.body.description')}
              smallText={error}
            />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default MeetingJoinDetails;
