// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import styled from 'styled-components';

interface Props {
  showNav: boolean;
  showRoster: boolean;
  showChat: boolean;
}

export const StyledLayout = styled.main<Props>`
  height: 100vh; // old browsers
  height: 100dvh; // new browsers
  /* min-height: -webkit-fill-available; */
  width: 100%;
  display: grid;

  .video-content {
    grid-area: content;
  }

  ${({ showNav, showRoster, showChat }) => {
    if (showNav && (showRoster || showChat)) {
      return `
        grid-template-columns: auto auto 1fr;
        grid-template-areas: 'nav side-panel content';
      `;
    }

    if (showNav && (showRoster || showChat)) {
      return `
        grid-template-columns: auto auto 1fr;
        grid-template-areas: 'nav side-panel content';
      `;
    }

    if (showNav) {
      return `
        grid-template-columns: auto 1fr;
        grid-template-areas: 'nav content';
      `;
    }

    if (showRoster || showChat) {
      return `
        grid-template-columns: auto 1fr;
        grid-template-areas: 'side-panel content';
      `;
    }

    return `
      grid-template-columns: 1fr;
      grid-template-areas: 'content';
    `;
  }}

  .nav {
    grid-area: nav;
  }

  .roster,
  .chat {
    grid-area: side-panel;
    z-index: 2;
    height: 100vh;
    height: 100dvh;
  }

  @media screen and (min-width: 941px) {
    .mobile-toggle {
      display: none;
    }
  }
  #root aside {
    box-shadow: none;
    background-color: #9296993b !important;
    font-weight: 400 !important;
    color: #929699da;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-areas: 'content';

    .nav {
      grid-area: unset;
    }

    .chat,
    .roster {
      position: absolute;
      grid-area: unset;
      background-color: black !important;
      border-left: 0;
      border-right: 0;
      /* border-top: 0.0625rem solid rgb(212, 213, 216); */
      z-index: 1500;
      height: 100vh;
      height: 100dvh;
      width: 100%;
      /* width: calc(100% - 44px) !important; */
      /* margin-left: 44px; */
      /* margin-top: 10px; */
      /* height: 100vh; */
      /* width: 100vw; */
    }

    .ch-title {
      color: white;
    }

    .ch-btn-close .ch-icon,
    .close-button .ch-icon,
    .ch-buttons .ch-icon {
      color: white;
    }

    .nav-bmv {
      z-index: 1500;
      height: 100vh;
      max-height: -webkit-fill-available;
      width: 100% !important;
      /* order: 2; */
      position: absolute;
      margin: 0;
      /* bottom: 0 !important; */
    }
    #root aside {
      z-index: 12;
      height: 40vh;

      background-color: black !important;
    }
  }
`;

export const StyledContent = styled.div`
  position: relative;
  grid-area: content;
  height: 100%;
  display: flex;
  flex-direction: column;

  .videos {
    flex: 1;
  }

  .controls {
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .bmv-video-tile-grid {
    grid-template: unset;
    /* grid-template-columns: 80% 20%; */
  }

  .share-and-remote {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: 25%;
    grid-template-columns: unset;
    grid-template-rows: unset;
    /* grid-auto-columns: auto; */
    /* grid-template-columns: minmax(250px, 1fr) minmax(250px, 1fr); */
    overflow: auto;
    /* grid-auto-rows: auto; */
  }

  @media screen and (max-width: 940px) {
    .controls {
      position: static;
      transform: unset;
      height: 4em;
    }
  }

  @media (min-width: 600px) and (max-width: 940px) {
    /* #bmv {
      grid-area: unset !important;
    } */

    .bmv-video-tile-grid {
      /* height: 100vh;
      height: 100dvh; */
      grid-template: none;
      grid-auto-flow: unset;
      /* grid-auto-columns: min-content min-content auto auto; */
      grid-template-columns: 1fr !important;
      /* grid-template-rows: 1fr 1fr 1fr; */
      grid-auto-rows: 100vh;
      grid-auto-rows: 100dvh;
      /* grid-template-areas: 'p1 p2' 's1 s2' 'w1 w2'; */
      /* grid-template-areas: 'p1 p2' 's1 s2' 'w1 w2'; */
      /* grid-template-areas: 'p1 p2' 's1 s2'; */
      /* grid-template-rows: 1fr !important;
      grid-template-columns: 1fr !important;
      grid-template-areas: 'ft' !important; */
      /* grid-template: 'ft' 1fr / 1fr !important; */
    }
  }

  @media (max-width: 600px) {
    .bmv-video-tile-grid {
      grid-template: unset;
      grid-template-areas: unset;
      grid-template-columns: 1fr !important;
      grid-auto-rows: calc(33.33333%);
    }

    .remote-videos {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: 33.3333vw;
      grid-auto-rows: 33.3333dvw;
    }
  }
`;

// .nav {
//   grid-area: unset;
//   position: fixed;
// }
// .chat,
// .roster {
//   grid-area: unset;
//   position: fixed;
//   top: 306px;
//   left: 40px;
//   right: 0;
//   bottom: 0;
//   background-color: black !important;

//   width: calc(100% - 40px) !important;
//   height: 212px;
// }
// .chat {
//   z-index: 15;
// }
// .roster {
//   z-index: 10;
// }
// .chat > div:first-child,
// .roster > div:first-child {
//   padding: 0 20px;
// }

// .nav-bmv {
//   z-index: 10;
//   margin-top: 290px;
//   height: 230px;
//   width: 100% !important;
// }
// #root aside {
//   margin-top: 335px;
//   z-index: 12;
//   margin-top: 550px;
//   height: 40vh;
//   background-color: black !important;
// }
