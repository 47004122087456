import React, { MutableRefObject, useEffect, useRef } from 'react';
import { useAppState } from '../providers/AppStateProvider';
import Player from '../bemyvega/GalleryVideoTileGrid/components/Player';
import { getButtons } from '../utils/playerUtils';

const useSetPlayers = () => {
  const { totalCams } = useAppState();

  const playerRefs: MutableRefObject<Player>[] = [];

  const roomId = location.pathname.slice(
    location.pathname.lastIndexOf('/') + 1
  );

  for (let i = 0; i < totalCams; i++) {
    playerRefs.push(useRef<Player>('r' + i));
  }

  const index = useRef(0);

  // Display only one player at a time
  function setPlayers() {
    for (let i = 0; i < totalCams; i++) {
      const wrapper = document.getElementById(`wp-p${i}`);
      if (i != index.current) {
        if (wrapper) {
          wrapper.style.display = 'none';
        }
      } else {
        if (wrapper) {
          wrapper.style.display = 'flex';
        }
      }
    }
  }
  useEffect(() => {
    setPlayers();

    // Change cams
    document.body.addEventListener('click', function(event) {
      const { previousButtons, nextButtons } = getButtons();
      for (let i = 0; i < previousButtons.length; i++) {
        // Change to previous cam
        if (previousButtons[i].contains(event.target as Node)) {
          index.current = index.current - 1;
          if (index.current < 0) {
            index.current = totalCams - 1;
          }
          setPlayers();

          // Change to next cam
        } else if (nextButtons[i].contains(event.target as Node)) {
          index.current = index.current + 1;
          if (index.current > totalCams - 1) {
            index.current = 0;
          }
          setPlayers();
        }
      }
    });

    // Mute or unmute all players at once
    document.body.addEventListener('click', function(event) {
      const { soundButtons } = getButtons();
      for (let i = 0; i < soundButtons.length; i++) {
        if (soundButtons[i].contains(event.target as Node)) {
          const isMute = playerRefs[i].current.getMute();
          for (let n = 0; n < totalCams; n++) {
            playerRefs[n].current.setMute(isMute);
          }
          break;
        }
      }
    });

    // Control the volume of all players at once
    document.body.addEventListener('click', function(event) {
      const { volumeSlider } = getButtons();
      for (let i = 0; i < volumeSlider.length; i++) {
        if (volumeSlider[i].contains(event.target as Node)) {
          const volume = playerRefs[i].current.getVolume();
          console.log('Volume is ' + volume);
          for (let n = 0; n < totalCams; n++) {
            playerRefs[n].current.setMute(false);
            playerRefs[n].current.setVolume(volume);
          }
          break;
        }
      }
    });

    // Hide fullscreen buttons of all player
    // const { fullScreenButtons } = getButtons();
    // for (let i = 0; i < fullScreenButtons.length; i++) {
    //   fullScreenButtons[i].style.display = 'none';
    // }
  }, []);

  const players = [];
  for (let i = 0; i < totalCams; i++) {
    players.push(
      <Player
        // key={i}
        ref={playerRefs[i]}
        key={i}
        id={`p${i}`}
        autoRefresh={true}
        sources={[
          {
            // type: 'mp4',
            type: 'webrtc',
            file: `wss://platform.bemyvega.dev/app/${roomId}-${i + 1}`,
            // file:
            //   'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
          },
        ]}
      />
    );
  }

  return players;
};

export default useSetPlayers;
