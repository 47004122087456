// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React from 'react';
import {
  ControlBar,
  // AudioInputVFControl,
  AudioInputControl,
  ContentShareControl,
  AudioOutputControl,
  ControlBarButton,
  useUserActivityState,
  Dots,
  VideoInputControl,
} from 'amazon-chime-sdk-component-library-react';
import { t } from 'i18next';

import EndMeetingControl from '../EndMeetingControl';
import { useNavigation } from '../../providers/NavigationProvider';
import { StyledControls } from './Styled';
import { useAppState } from '../../providers/AppStateProvider';
import { VideoFiltersCpuUtilization } from '../../types';
import VideoInputTransformControl from '../../components/MeetingControls/VideoInputTransformControl';

const MeetingControls: React.FC = () => {
  const { toggleNavbar, closeRoster, showRoster } = useNavigation();
  const { isUserActive } = useUserActivityState();
  const { videoTransformCpuUtilization } = useAppState();
  const videoTransformsEnabled =
    videoTransformCpuUtilization !== VideoFiltersCpuUtilization.Disabled;

  const handleToggle = (): void => {
    if (showRoster) {
      closeRoster();
    }
    toggleNavbar();
  };

  return (
    <StyledControls className="controls bmv-controls" active={!!isUserActive}>
      <ControlBar
        className="controls-menu"
        layout="undocked-horizontal"
        showLabels
      >
        <ControlBarButton
          className="mobile-toggle"
          icon={<Dots />}
          onClick={handleToggle}
          label="Menu"
        />
        {/* { isWebAudioEnabled ? <AudioInputVFControl /> :  <AudioInputControl /> } */}
        {
          <AudioInputControl
            muteLabel={t('meetingControls.audioInput.muteLabel')}
            unmuteLabel={t('meetingControls.audioInput.unmuteLabel')}
            mutedIconTitle={t('meetingControls.audioInput.mutedIconTitle')}
            unmutedIconTitle={t('meetingControls.audioInput.unmutedIconTitle')}
          />
        }
        {videoTransformsEnabled ? (
          <VideoInputTransformControl
            label={t('meetingControls.videoTransform.label')}
            backgroundBlurLabel={t(
              'meetingControls.videoTransform.backgroundBlurLabel'
            )}
            backgroundReplacementLabel={t(
              'meetingControls.videoTransform.backgroundReplacementLabel'
            )}
          />
        ) : (
          <VideoInputControl
            label={t('meetingControls.videoTransform.label')}
          />
        )}
        <ContentShareControl
          label={t('meetingControls.contentShare.label')}
          pauseLabel={t('meetingControls.contentShare.pauseLabel')}
          unpauseLabel={t('meetingControls.contentShare.unpauseLabel')}
          iconTitle={t('meetingControls.contentShare.iconTitle')}
        />
        <AudioOutputControl label={t('meetingControls.audioOutput.label')} />
        <EndMeetingControl />
      </ControlBar>
    </StyledControls>
  );
};

export default MeetingControls;
